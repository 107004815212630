<template>
  <div class="card-wrapper ">
    <CardFram :class="{ 'card-overlay': !secondCardDone }">
      <template #header>{{ $t("card.title") }}</template>
      <template #body>
        <form>
          <formWrapper :validator="$v.form">
            <b-row>
              <b-col
                cols="12"
                v-for="(formKey, index) in formKeys"
                :key="index"
              >
                <!-- <b-form-textarea id="textarea-rows" rows="8"></b-form-textarea> -->
                <form-group :name="formKey" :attribute="`input.${formKey}`">
                  <b-form-textarea
                    slot-scope="{ attrs, listeners }"
                    v-on="listeners"
                    v-bind="attrs"
                    v-model="form[formKey]"
                    class="third-card-input "
                    no-resize
                    rows="8"
                    v-inputFocus="{
                      index,
                      className: 'third-card-span',
                      formKey,
                      form
                    }"
                  ></b-form-textarea>
                </form-group>
                <span
                  v-label-focus="{ index, className: 'third-card-input' }"
                  class="label-default third-card-span font-light"
                >
                  {{ $t(`input.${formKey}`) }}
                </span>
              </b-col>
              <b-col cols="12" class="margin-top">
                <!-- <imageUpload :maxSize="maxSize" @fileSelected="fileSelected" /> -->
                <multiImageUpload
                  :maxSize="maxSize"
                  @fileSelected="handleAttachment"
                />
              </b-col>
            </b-row>
          </formWrapper>
        </form>
      </template>
      <template #footer>
        <b-col cols="12">
          <b-button
            @click="handleSubmitData"
            class="nextButton font-bold text-center "
            variant="default"
          >
            <b-spinner medium v-if="loadingButton"></b-spinner>
            <span v-else> {{ $t("button.finish") }}</span>
          </b-button>
        </b-col>
      </template>
    </CardFram>
  </div>
</template>

<script>
import { maxWords, minWords } from "@/utils/validate";
import { StoreData } from "@/helpers/apiMethods";
export default {
  name: "ThirdCard",

  props: {
    secondCardDone: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => {}
    },
    showOverLay: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CardFram: () => import("@/components/CardFram"),
    MultiImageUpload: () => import("@/components/multiImageUpload")
  },

  data() {
    return {
      form: {
        message: ""
      },
      uploaded_files: [],
      maxSize: 4069,
      loadingButton: false
    };
  },
  methods: {
    resetForm() {
      this.form = {
        message: ""
      };
      this.uploaded_files = null;
      this.$store.dispatch("ClearServerErrors");
      this.$v.form.$reset();
    },
    fileSelected(dataFile) {
      this.file = dataFile.file;
    },
    handleSubmitData() {
      const submitedData = { ...this.form, ...this.formData };

      this.loadingButton = true;

      const formData = new FormData();

      Object.keys(submitedData).map(formKey => {
        if (formKey !== "companies_ids") {
          formData.append(formKey, submitedData[formKey]);
        } else {
          submitedData[formKey].map((companyId, index) =>
            formData.append(`companies_ids[${index}]`, companyId)
          );
        }
      });
      this.uploaded_files !== null &&
        this.uploaded_files.forEach((file, index) =>
          formData.append(`uploaded_files[${index}]`, file)
        );

      StoreData({ reqName: "investments", data: formData })
        .then(() => {
          this.$emit("data_sucess");
        })
        .catch(err => console.log(err))
        .finally(() => (this.loadingButton = false));
    },
    handleAttachment(attachment) {
      //   console.log("attachment", attachment);
      this.uploaded_files = attachment;
      //   console.log(attchment);
    }
  },
  computed: {
    formKeys() {
      return Object.keys(this.form);
    }
  },
  watch: {
    showOverLay: {
      handler(value) {
        if (!value) {
          this.resetForm();
        }
      },
      immediate: true
    }
  },
  validations: {
    form: {
      message: {
        minWords: minWords(10),
        maxWords: maxWords(2000)
      }
    }
  }
};
</script>
